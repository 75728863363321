class Paginacao {
	
	constructor(configuracao, id_render_paginacao){

		// Primeiros Atributos, tudo o que a classe for receber
		this.id_render_paginacao = id_render_paginacao;
		this.configuracao = configuracao;
		this.render_element = [];
		for (var i = this.id_render_paginacao.length - 1; i >= 0; i--) {
			if(document.getElementById(this.id_render_paginacao[i])){
				this.render_element.push(document.getElementById(this.id_render_paginacao[i]));
			}
		}
		this.rules(); // Valida esses parametros
	}

	// Função que valida os parâmetros e da classe
	rules(){

		if(typeof(this.id_render_paginacao) == 'undefined'){
			console.warn('Ops, vou precisar que você informe o ID do elemento para renderizar a paginação.');
			return false;
		}

		if(typeof(this.configuracao) == 'undefined'){
			console.warn('Ops, vou precisar que você informe a configuração da paginação.');
			return false;
		}

		if(this.render_element == 0){
			console.warn('Ops, parece que o ID elemento que você informou para renderizar a paginação não existe.');
			return false;
		}
	}

	set setConfiguracao(configuracao){
		if(this.isDicionario(configuracao)){
			this.configuracao = configuracao;
		}
	}

	/* Função responsavel por dar replace na mascaras */
	static replace(string, procurar, substituir){

		let i,
			regex 	= [],
			map 	= {};

		for(i = 0; i < procurar.length; i++){

			regex.push( procurar[i].replace(/([-[\]{}()*+?.\\^$|#,])/g,'\\$1'));
			map[procurar[i]] = substituir[i];
		}

		regex = regex.join('|');

		string = string.replace( new RegExp( regex, 'g' ), function(matched){
			return map[matched];
		});

		return string;
	}

	isDicionario(dicionario){
		return typeof dicionario === 'object' && dicionario !== null && !(dicionario instanceof Array) && !(dicionario instanceof Date);
	}

	static back(totalpagina, paginaatual){

		var back = paginaatual - 1;
		var atributo = '';
		var classe = '';
		var onclick =  `onclick="Paginacao.acao(${back});"`;
		if(paginaatual == 1){
			classe = 'b-paginacao-disabled';
			atributo = 'disabled="disabled"';
		}

		var botao = `<button ${onclick} class="b b-paginacao bb-col ${classe}" ${atributo} type="button"><img src="/jnh/icones/left.png" class="back-ar"></button>`;

		return botao;
	}

	static anterior(totalpagina, paginaatual){

		paginaatual = Number(paginaatual);
		totalpagina = Number(totalpagina);

		var anterior = paginaatual - 1;

		if((paginaatual - 1) < totalpagina){
			anterior = paginaatual - 1;
		}

		var atributo = '';
		var classe = '';
		var active = '';
		var onclick = '';
		if(paginaatual == 1 && anterior == 0){
			anterior = 1;
			active = 'b-paginacao-active';
			atributo = 'disabled="disabled"';
		}

		if(paginaatual == totalpagina){
			anterior -= 1;
		}

		onclick = `onclick="Paginacao.acao(${anterior})"`;

		var html = `<button ${onclick} class="b b-paginacao ${active} ${classe}" ${atributo} type="button">${anterior}</button>`;

		return html;
	}

	static atual(totalpagina, paginaatual){

		paginaatual = Number(paginaatual);
		totalpagina = Number(totalpagina);

		var classe = '';
		var onclick = '';
		var active = 'b-paginacao-active';

		if(paginaatual == 1){
			paginaatual = 1;
		}

		if(paginaatual == totalpagina){
			paginaatual -= 1;
			active = '';
		}

		paginaatual = !paginaatual ? 1 : paginaatual;

		if(paginaatual == 1 && totalpagina >= 3){
			paginaatual = 2;
			active = '';
		}

		onclick = `onclick="Paginacao.acao(${paginaatual})"`;

		var html = `<button ${onclick} class="b b-paginacao ${active}" type="button">${paginaatual}</button>`;

		return html;
	}

	static proxima(totalpagina, paginaatual){

		paginaatual = Number(paginaatual);
		totalpagina = Number(totalpagina);

		var proxima = paginaatual + 1;
		var atributo = '';
		var classe = '';
		var active = '';
		var onclick = '';
		if(paginaatual == totalpagina){
			classe = 'b-paginacao-disabled';
			atributo = 'disabled="disabled"';
		}

		if(proxima == 2 && paginaatual == 2){
			proxima = 3;
		}

		if(proxima > totalpagina){
			active = 'b-paginacao-active';
			proxima = totalpagina;
			classe = '';
		}

		if(proxima == 2 && totalpagina >= 3){
			proxima = 3;
			active = '';
			classe = '';
		}
	
		onclick = `onclick="Paginacao.acao(${proxima})"`;

		var html = `<button ${onclick} class="b b-paginacao ${active} ${classe}" ${atributo} type="button">${proxima}</button>`;

		return html;
	}

	static all(totalpagina, paginaatual){

		paginaatual = Number(paginaatual);
		totalpagina = Number(totalpagina);

		var atributo = '';
		var classe = '';
		var onclick = `onclick="Paginacao.allPaginas();"`;
		if(totalpagina < 3 /*|| paginaatual == totalpagina*/){
			onclick = '';
			classe = 'b-paginacao-disabled';
			atributo = 'disabled="disabled"';
		}

		var html = `<button ${onclick} class="b b-paginacao ${classe}" ${atributo} type="button">...</button>`;

		return html;
	}

	static next(totalpagina, paginaatual){

		paginaatual = Number(paginaatual);

		var next = paginaatual + 1;
		var atributo = '';
		var classe = '';
		var onclick = `onclick="Paginacao.acao(${next})"`;
		if(paginaatual == totalpagina){
			onclick = '';
			classe = 'b-paginacao-disabled';
			atributo = 'disabled="disabled"';
		}

		var botao = `<button ${onclick} class="b b-paginacao bb-col ${classe}" ${atributo} type="button"><img src="/jnh/icones/right.png" class="back-ar"></button>`;

		return botao;
	}

	allPaginas(){

		let botoes = '';
		var paginatotal = this.configuracao['paginatotal'];
		var paginaatual = this.configuracao['paginaatual'];

		for (var i = 1; i < paginatotal + 1; i++){


			var active = '';
			if(paginaatual == i){
				active = 'b-paginacao-active';
			}

			botoes += `<button onclick="Boss.dialog.close(); Paginacao.acao(${i});" class="b b-paginacao ${active}">${i}</button>`;
		}

		let html = `
			<div class="dialog-paginacao">
				<div class="boss-dialog-area">
					<h2>Páginas encontradas - Página Atual ${paginaatual}</h2>
					<div class="container_botoes">
						${botoes}
					</div>
					<p class="text-center"><button onclick="Boss.dialog.close();" class="b b-primary"><i class="icl ic-times"></i> Fechar</button></p>
				</div>
			</div>
		`;
		
		Boss.dialog.open({
			'invisible': false,
			'close': true,
			'html': html
		});
	}

	acao(pagina){

		var antes = this.configuracao;
		payloadBusca.add(pagina, 'pag');
		payloadBusca.send();

		var mascara = this.configuracao['mascara'];

		var totalpagina = this.configuracao['paginatotal'];
		var paginaatual = this.configuracao['paginaatual'];
		var render_element = this.render_element;
		
		Paginacao.renderHTML(mascara, totalpagina, paginaatual, render_element);
	}

	static renderHTML(mascara, totalpagina, paginaatual, render_element){
		var back 		= Paginacao.back(totalpagina, paginaatual);
		var anterior 	= Paginacao.anterior(totalpagina, paginaatual);
		var atual		= Paginacao.atual(totalpagina, paginaatual);
		var proxima 	= Paginacao.proxima(totalpagina, paginaatual);
		var all 		= Paginacao.all(totalpagina, paginaatual);
		var next 		= Paginacao.next(totalpagina, paginaatual);

		var html = ''
		if(totalpagina <= 1){
			html = (back + atual + next).toString();
		}else if(totalpagina == 2){
			html = (back + atual + proxima + next).toString();
		}else if(totalpagina == 3){
			html = (back + anterior + atual + proxima + next).toString();
		}else{
			html = (back + anterior + atual + proxima + all + next).toString();
		}

		for (var i = render_element.length - 1; i >= 0; i--) {
			if(render_element[i]){
				render_element[i].innerHTML = html;
			}
		}
	}

	render(){

		var mascara = this.configuracao['mascara'];
		var totalpagina = this.configuracao['paginatotal'];
		var paginaatual = this.configuracao['paginaatual'];

		Paginacao.renderHTML(mascara, totalpagina, paginaatual, this.render_element);
	}
}