/* INICIO FAVORITO */
window.triggerfav = (botao, imo_codigo) =>{

	if(botao){

		let like = '/jnh/icones/like.png';
		let unlike = '/jnh/icones/unlike.png';
		if(botao.querySelector("IMG").getAttribute("src") == like){

			confirmarremoverfav(botao, imo_codigo);
		}else if(botao.querySelector("IMG").getAttribute("src") == unlike){
			addfav(botao, imo_codigo);
		}
	}
}
window.triggerbtnfav = (botao, acao) => {

	if(botao){

		let like = '/jnh/icones/like.png';
		let unlike = '/jnh/icones/unlike.png';
		console.log(acao);
		if(acao === 1){
			botao.querySelector("IMG").src = like;
		}
		if(acao === 2){
			botao.querySelector("IMG").src = unlike;
		}
	}
}
window.addfav = (botao, imo_codigo) => {

	triggerbtnfav(botao, 1);
	
	Boss.ajax({
		url: '/favoritos/add',
		data: {'imo_codigo': imo_codigo},
		dataType: 'json',
		done: rtn => {}
	});
}

window.confirmarremoverfav = (botao, imo_codigo) => {

	triggerbtnfav(botao, 2);
	Boss.ajax({
		url: '/favoritos/remover',
		data: {'imo_codigo': imo_codigo},
		dataType: 'json',
		done: rtn => {}
	});
}
/* FIM FAVORITO */